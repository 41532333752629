import type { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { EditorReadyFn } from '@wix/yoshi-flow-editor';

const presets = { desktop: 'variants-lsohmxuo3', mobile: 'variants-lsrikgdr' };

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  if (options.firstInstall) {
    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: '59029373-fd04-46f3-95ea-012bc79473db',
      installationType: 'closed' as WidgetInstallationType,
      layout: {
        // @ts-expect-error
        docked: {
          left: {
            px: 0,
            vw: 0,
          },
          right: {
            px: 0,
            vw: 0,
          },
        },
        height: 100,
        width: 980,
        x: 0,
        y: 0,
      },
      scopedPresets: {
        desktop: {
          layout: presets.desktop,
          style: presets.desktop,
        },
        mobile: {
          layout: presets.mobile,
          style: presets.mobile,
        },
      },
      layouts: {
        componentLayout: {
          minHeight: {
            type: 'px',
            value: 280,
          },
          hidden: false,
          height: {
            type: 'auto',
          },
          type: 'ComponentLayout',
          width: {
            type: 'auto',
          },
        },
        itemLayout: {
          id: '',
          alignSelf: 'center',
          gridArea: {
            rowStart: 1,
            rowEnd: 2,
            columnStart: 1,
            columnEnd: 2,
          },
          justifySelf: 'center',
          type: 'GridItemLayout',
        },
      },
    });
  }
};
